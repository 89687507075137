
import {Vue, Options} from "vue-class-component";

@Options({})
export default class MonochromePosterPage extends Vue
{
    private _posters: string[] = [];
    private _currentPoster: number = -1;

    public get hasPosters(): boolean{return this._posters.length > 0}
    public get currentPoster(): string
    {
        if (!this._posters[this._currentPoster])
            return `url(${require("@/assets/images/default-monochrome-poster.jpeg")})`;

        return `url(${this._posters[this._currentPoster]})`;
    }

    public created(): void
    {
        this._loadPosters();

        this._loopPosters();
        setInterval(this._loopPosters, 5000);
    }

    private _loopPosters(): void
    {
        if (!this.hasPosters)
            return;
        
        let nextIndex = this._currentPoster + 1;
        if (nextIndex >= this._posters.length)
        {
            nextIndex = 0;
            this._loadPosters();
        }

        this._currentPoster = nextIndex;
    }

    /**
     * Load monochrome posters and update the posters state.
     * 
     */
    private async _loadPosters(): Promise<void>
    {
        let response;
        try {response = await fetch(`${process.env.VUE_APP_BACKEND}/monochrome-photos`, {method: "GET"})}
        catch (error){return}

        try {this._posters = await response.json()}
        catch (error){return}
    }
};
