
import {Vue, Options} from "vue-class-component";

@Options({
    emits: ["update:modelValue"],
    props: {
        modelValue: {
            type: Boolean
        }
    }
})
export default class checkbox extends Vue
{
    public modelValue!: boolean;
};
