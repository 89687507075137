
import {Vue, Options} from "vue-class-component";

// Components
import TopHeader from "@/components/TopHeader.vue";

@Options({
    components: {
        TopHeader
    }
})
export default class UploadPosterPage extends Vue
{
    public mounted(): void
    {
        setTimeout(this._resetApplication, 30000);
    }

    /**
     * Restart application from start.
     */
    private _resetApplication(): void
    {
        this.$router.afterEach(()=> location.reload());
        this.$router.push("/");
    }
};
