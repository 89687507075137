export default {
    namespaced: true, 
    state: {
        party: -1,
        slogan: -1,
        poster: "",
        photo: null
    },
    getters: {
        hasParty(state: VuexPartyState): boolean
        {
            return state.party >= 0;
        },
        hasSlogan(state: VuexPartyState): boolean
        {
            return state.slogan >= 0;
        },
        hasPhoto(state: VuexPartyState): boolean
        {
            return !!state.photo;
        }
    }, 
    mutations: {
        setParty(state: VuexPartyState, option: number): void
        {
            state.party = option;
            state.slogan = -1;
        },
        setSlogan(state: VuexPartyState, option: number): void
        {
            state.slogan = option;
        },
        setPhoto(state: VuexPartyState, option: string): void
        {
            state.photo = option;
        },
        setPoster(state: VuexPartyState, option: string): void
        {
            state.poster = option;
        }
    }
};