
import {Vue, Options} from "vue-class-component";

@Options({
    emits: ["click"], 
    props: {
        href: {
            type: String, 
            required: false
        }, 
        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    }
})
export default class SubmitButton extends Vue
{
    public href!: string;
    public disabled!: boolean;
};
