
import {mapState} from "vuex";
import {Options, Vue} from "vue-class-component";

// Content
import parties from "@/assets/content/parties";

// Components
import Checkbox from "@/components/Checkbox.vue";
import TopHeader from "@/components/TopHeader.vue";
import SubmitButton from "@/components/SubmitButton.vue";

@Options({
    components: {
        Checkbox,
        TopHeader,
        SubmitButton
    }, 
    computed: mapState("party", {
        active(state: VuexPartyState): number{return state.party}
    })
})
export default class ChooseParty extends Vue
{
    public active!: number;
    public parties: {image: string, slogans: {label: string, image: string}[]}[] = parties;

    public get hasParty(): boolean
    {
        return this.$store.getters["party/hasParty"];
    }

    public setParty(index: number): void
    {
        this.$store.commit("party/setParty", index);
    }
};
