
import {Vue, Options} from "vue-class-component";
// Lib
import RecordUtility from "@/lib/RecordUtility";
// Components
import SubmitButton from "@/components/SubmitButton.vue";
import WebcamLiveFeed from "@/components/WebcamLiveFeed.vue";
import {mapState} from "vuex";
@Options({
    components: {
        SubmitButton,
        WebcamLiveFeed
    },
    computed: mapState("party", {
        photo(state: VuexPartyState): Nullable<string>{return state.photo}
    }),
    watch: {
        photo(value: Nullable<string>, previousValue: Nullable<string>): void
        {
            if (!value || value === previousValue)
                return;
            this._onPhotoReady();
        }
    }
})
export default class Photopage extends Vue
{
    public photo!: Nullable<string>;
    public timerLength: number = 5000;
    private _recordUtility!: RecordUtility;
    public timerIntance: Nullable<number> = null;
    public timer: number = this.timerLength + 1000;
    public get canTakePicture(): boolean{return this.timer - 1000 === this.timerLength}
    public get remaingTimeInSeconds(): number{return this.timer / 1000}
    /**
     * Callback when livefeed is ready.
     * 
     * @param video 
     */
    public onVideoReady(video: HTMLVideoElement): void
    {
        this._recordUtility = new RecordUtility(video);
    }
    /**
     * Callback when photo button is pressed.
     * Starts the photo timer.
     */
    public onPhotoButtonPressed(): void
    {
        this._onTick();
        this.timerIntance = setInterval(this._onTick, 1000);
    }
    /**
     * On interval tick.
     * Check if timer is finished and call takePhoto.
     */
    private _onTick(): void
    {
        if (this.timer > 1000)
        {
            this.timer -= 1000;
            return;
        }
        clearInterval(this.timerIntance as number);
        this.timerIntance = null;
        this.takePhoto();
    }
    /**
     * Take a photo and store it in the store.
     */
    public async takePhoto(): Promise<void>
    {
        const photo = await this._recordUtility.takeScreenshot();

        fetch(photo)
            .then(response=> response.blob())
            .then(blob=>
            {
                const formData = new FormData();
                formData.append("photo", blob, "monochrome-poster.jpeg");
                return fetch(`${process.env.VUE_APP_BACKEND}/monochrome-photo`, {method: "POST", body: formData});
            })
            .catch(console.error);

        this.$store.commit("party/setPhoto", photo);
    }

    private _onPhotoReady(): void
    {
        this.$router.push("/partij-kiezen");
    }
};
