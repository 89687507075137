
import {mapState} from "vuex";
import {Vue, Options} from "vue-class-component";

// Content
import content from "@/assets/content/parties";

// Components
import TopHeader from "@/components/TopHeader.vue";
import SubmitButton from "@/components/SubmitButton.vue";

@Options({
    components: {
        TopHeader,
        SubmitButton
    },
    computed: mapState("party", {
        partyIndex(state: VuexPartyState): number{return state.party},
        sloganIndex(state: VuexPartyState): number{return state.slogan},
        photo(state: VuexPartyState): Nullable<string>{return state.photo},
        poster(state: VuexPartyState): string{return state.poster}
    })
})
export default class PosterPreviewPage extends Vue
{
    public poster!: string;
    public partyIndex!: number;
    public sloganIndex!: number;
    public photo!: Nullable<string>;

    public async mounted(): Promise<void>
    {
        const [photo, poster] = await Promise.all([this._loadImage(this.photo as string), this._loadImage(content[this.partyIndex].slogans[this.sloganIndex].image)]);

        const canvas = document.createElement("canvas");
        canvas.width = 768;
        canvas.height = 1366;

        const scale = 1366 / photo.height;
        const x = ((photo.width * scale) - 768) / 2;

        const context = canvas.getContext("2d") as CanvasRenderingContext2D;
        context.drawImage(photo, -x, 0, photo.width * scale, photo.height * scale);
        context.drawImage(poster, 0, 0);

        this.$store.commit("party/setPoster", canvas.toDataURL("image/jpeg"));
    }

    /**
     * Restart the cycle.
     */
    public restart(): void
    {
        this.$router.afterEach(()=> location.reload());
        this.$router.push("/");
    }

    /**
     * Navigate to the next page.
     */
    public submit(): void
    {
        this.$router.push({name: "email-input"});
    }

    /**
     * Create image and resolve when loaded event is called.
     * 
     * @param src 
     * @returns The image element
     */
    private _loadImage(src: string): Promise<HTMLImageElement>
    {
        return new Promise((resolve)=>
        {
            const img = new Image();
            img.addEventListener("load", ()=>
            {
                document.body.removeChild(img);
                resolve(img);
            });
            img.crossOrigin = "";
            img.src = src;
            document.body.appendChild(img);
        });
    }
};
