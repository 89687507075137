
import {Options, Vue} from "vue-class-component";

// Components
import SubmitButton from "@/components/SubmitButton.vue";
import TopHeader from "@/components/TopHeader.vue";

@Options({
    components: {
        SubmitButton,
        TopHeader
    }
})
export default class HomePage extends Vue
{
};
