
import {Vue, Options} from "vue-class-component";

//Components
import Keyboard from "simple-keyboard";
import "simple-keyboard/build/css/index.css";

@Options({
    props: {
        modelValue: {
            type: String,
            required: true
        }
    },
    watch: {
        modelValue(value: string): void 
        {
            this._keyboard.setInput(value);
        }
    }
})
export default class SimpleKeyboard extends Vue
{
    private _keyboard?: Keyboard;
    private _previousButton: string = "";

    public mounted(): void
    {
        this._keyboard = new Keyboard(this.$refs.keyboard as HTMLDivElement, {
            onChange: this._onChange,
            onKeyPress: this._onKeyPress
        });
    }

    private _onKeyPress(button: string): void
    {        
        if (!this._keyboard) 
            return;

        if (this._previousButton === "{shift}" && button !== "{shift}")
            this._keyboard.setOptions({layoutName: "default"});

        this._previousButton = button;
        if (button !== "{shift}" && button !== "{lock}")
            return;

        const currentLayout = this._keyboard.options.layoutName;
        this._keyboard.setOptions({
            layoutName: currentLayout === "default" ? "shift" : "default"
        });
    }

    private _onChange(input: string): void
    {
        this.$emit("update:modelValue", input);
    }

};
