
import {mapState} from "vuex";
import {Options, Vue} from "vue-class-component";
import * as EmailValidator from "email-validator";

// Components
import TopHeader from "@/components/TopHeader.vue";
import SubmitButton from "@/components/SubmitButton.vue";
import SimpleKeyboard from "@/components/SimpleKeyboard.vue";

@Options({
    components: {
        SubmitButton, 
        TopHeader,
        SimpleKeyboard
    },
    computed: mapState("party", {
        poster(state: VuexPartyState): string{return state.poster}
    })
})
export default class Emailpage extends Vue
{
    public poster!: string;
    public input: string = "";
    private _resetTimer: number = 0;
    public invalidEmail: boolean = false;

    public mounted(): void
    {
        this.setResetTimer();
    }

    public async submit(): Promise<void>
    {
        // Validate emailaddress
        if (!EmailValidator.validate(this.input))
            return void(this.invalidEmail = true);

        // Create blob from the base64 string
        let posterBlob: Blob;
        try {posterBlob = await this._base64ToBlob(this.poster)}
        catch (error){return console.log("Failed to convert to blob", error)}

        const body = new FormData();
        body.append("email", this.input);
        body.append("poster", posterBlob);

        try {await fetch(`${process.env.VUE_APP_BACKEND}/upload-poster`, {method: "POST", body})}
        catch (error){return console.log("Failed to send poster", error)}

        this.$router.push({name: "upload-poster"});
    }

    private async _base64ToBlob(base64: string): Promise<Blob>
    {
        const response = await fetch(base64);
        return await response.blob();
    }

    public setResetTimer(): void
    {
        if (this._resetTimer)
            window.clearTimeout(this._resetTimer);

        this._resetTimer = window.setTimeout(this._resetApplication, 30000);
    }

    /**
     * Restart application from start.
     */
    private _resetApplication(): void
    {
        this.$router.afterEach(()=> location.reload());
        this.$router.push("/");
    }

    public beforeUnmount(): void
    {
        if (this._resetTimer)
            window.clearTimeout(this._resetTimer);
    }
};
