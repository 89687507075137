<template>
    <header>
        <h1>
            <slot>Verkiezingsposter</slot>
        </h1>
    </header>
</template>

<style lang="scss" scoped>
header
{
    padding: 4rem 0;

    h1
    {
        font-size: 5.5rem;
        font-weight: 300;
        text-align: center;
        color: var(--primary-0);
    }
}
</style>