<template>
    <router-view />
</template>

<style lang="scss">
@font-face
{
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    font-family: "Bigdots";
    src: url("@/assets/fonts/bigdots-webfont.woff") format("woff");
}
@font-face
{
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    font-family: "Eurostile";
    src: url("@/assets/fonts/eurostilebold-webfont.woff") format("woff");
}
@font-face
{
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    font-family: "Eurostile";
    src: url("@/assets/fonts/eurostilenormal-webfont.woff") format("woff");
}
@font-face
{
    font-weight: 400;
    font-style: italic;
    font-display: swap;
    font-family: "Eurostile";
    src: url("@/assets/fonts/eurostilebq-italic-webfont.woff") format("woff");
}

*{box-sizing: border-box}
:root
{
    --primary-0: #0099CC;
    --primary-1: #666666;
    --primary-2: #CCCCCC;

    --font-bigdots: "Bigdots", sans-serif;
    --font-eurostile: "Eurostile", sans-serif;
}

body, html, #app
{
    margin: 0px;
    width: 100%;
    height: 100%;
    padding: 0px;
}

#app
{
    text-align: center;
    font-family: var(--font-eurostile);
}

h1, h2, h3, h4, h5, h6
{
    margin: 0px;
    padding: 0px;
    font-family: var(--font-bigdots);
}

p
{
    margin: 0px;
    padding: 0px;
    letter-spacing: 0.2rem;
    font-family: var(--font-eurostile);
}

button
{
    border: none;
    outline: none;
    cursor: pointer;
    font-family: inherit;
    background: none;
}
</style>