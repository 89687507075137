
import {mapState} from "vuex";
import {Options, Vue} from "vue-class-component";

// Content
import parties from "@/assets/content/parties";

// Components
import Checkbox from "@/components/Checkbox.vue";
import TopHeader from "@/components/TopHeader.vue";
import SubmitButton from "@/components/SubmitButton.vue";

@Options({
    components: {
        Checkbox,
        TopHeader,
        SubmitButton
    }, 
    computed: mapState("party", {
        active(state: VuexPartyState): number{return state.slogan},
        activeParty(state: VuexPartyState): number{return state.party}
    })
})

export default class ChooseSlogan extends Vue
{
    public active!: number;
    public activeParty!: number;
    public slogans: {label: string, image: string}[] = [];

    public get hasSlogan(): boolean
    {
        return this.$store.getters["party/hasSlogan"];
    }

    public mounted():void
    {
        this.slogans = parties[this.activeParty].slogans;
    }

    public setSlogan(index: number): void
    {
        this.$store.commit("party/setSlogan", index);
    }
};
