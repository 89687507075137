export default [{
    image: require("@/assets/images/parties/logo_0.png"),
    slogans: [{
        label: "Vrijheid voor allen", 
        image: require("@/assets/images/posters/samen-sociaal/poster_samen_sociaal_vrijheid_voor_allen.png")
    },
    {
        label: "De jeugd heeft de toekomst", 
        image: require("@/assets/images/posters/samen-sociaal/poster_samen_sociaal_de_jeugd_heeft_de_toekomst.png")
    },
    {
        label: "Meer inspraak voor iedereen", 
        image: require("@/assets/images/posters/samen-sociaal/poster_samen_sociaal_meer_inspraak_voor_iedereen.png")
    },
    {
        label: "Samen staan we sterk", 
        image: require("@/assets/images/posters/samen-sociaal/poster_samen_sociaal_samen_staan_we_sterk.png")
    },
    {
        label: "Dat kan groener", 
        image: require("@/assets/images/posters/samen-sociaal/poster_samen_sociaal_dat_kan_groener.png")
    },
    {
        label: "Maakt Nederland beter", 
        image: require("@/assets/images/posters/samen-sociaal/poster_samen_sociaal_maakt_nederland_beter.png")
    },
    {
        label: "Opkomen voor elkaar", 
        image: require("@/assets/images/posters/samen-sociaal/poster_samen_sociaal_opkomen_voor_elkaar.png")
    },
    {
        label: "Wij helpen Nederland vooruit!", 
        image: require("@/assets/images/posters/samen-sociaal/poster_samen_sociaal_wij_helpen_nederland_vooruit.png")
    },
    {
        label: "Naastenliefde en gerechtigheid", 
        image: require("@/assets/images/posters/samen-sociaal/poster_samen_sociaal_naastenliefde_en_gerechtigheid.png")
    }]
},
{
    image: require("@/assets/images/parties/logo_1.png"),
    slogans: [{
        label: "Vrijheid voor allen", 
        image: require("@/assets/images/posters/natuurlijk-nu/poster_natuurlijk_nu_vrijheid_voor_allen.png")
    },
    {
        label: "De jeugd heeft de toekomst", 
        image: require("@/assets/images/posters/natuurlijk-nu/poster_natuurlijk_nu_de_jeugd_heeft_de_toekomst.png")
    },
    {
        label: "Meer inspraak voor iedereen", 
        image: require("@/assets/images/posters/natuurlijk-nu/poster_natuurlijk_nu_meer_inspraak_voor_iedereen.png")
    },
    {
        label: "Samen staan we sterk", 
        image: require("@/assets/images/posters/natuurlijk-nu/poster_natuurlijk_nu_samen_staan_we_sterk.png")
    },
    {
        label: "Dat kan groener", 
        image: require("@/assets/images/posters/natuurlijk-nu/poster_natuurlijk_nu_dat_kan_groener.png")
    },
    {
        label: "Maakt Nederland beter", 
        image: require("@/assets/images/posters/natuurlijk-nu/poster_natuurlijk_nu_maakt_nederland_beter.png")
    },
    {
        label: "Opkomen voor elkaar", 
        image: require("@/assets/images/posters/natuurlijk-nu/poster_natuurlijk_nu_opkomen_voor_elkaar.png")
    },
    {
        label: "Wij helpen Nederland vooruit!", 
        image: require("@/assets/images/posters/natuurlijk-nu/poster_natuurlijk_nu_wij_helpen_nederland_vooruit.png")
    },
    {
        label: "Naastenliefde en gerechtigheid", 
        image: require("@/assets/images/posters/natuurlijk-nu/poster_natuurlijk_nu_naastenliefde_en_gerechtigheid.png")
    }]
},
{
    image: require("@/assets/images/parties/logo_2.png"),
    slogans: [{
        label: "Vrijheid voor allen", 
        image: require("@/assets/images/posters/vrijheid-voorop/poster_vrijheid_voorop_vrijheid_voor_allen.png")
    },
    {
        label: "De jeugd heeft de toekomst", 
        image: require("@/assets/images/posters/vrijheid-voorop/poster_vrijheid_voorop_de_jeugd_heeft_de_toekomst.png")
    },
    {
        label: "Meer inspraak voor iedereen", 
        image: require("@/assets/images/posters/vrijheid-voorop/poster_vrijheid_voorop_meer_inspraak_voor_iedereen.png")
    },
    {
        label: "Samen staan we sterk", 
        image: require("@/assets/images/posters/vrijheid-voorop/poster_vrijheid_voorop_samen_staan_we_sterk.png")
    },
    {
        label: "Dat kan groener", 
        image: require("@/assets/images/posters/vrijheid-voorop/poster_vrijheid_voorop_dat_kan_groener.png")
    },
    {
        label: "Maakt Nederland beter", 
        image: require("@/assets/images/posters/vrijheid-voorop/poster_vrijheid_voorop_maakt_nederland_beter.png")
    },
    {
        label: "Opkomen voor elkaar", 
        image: require("@/assets/images/posters/vrijheid-voorop/poster_vrijheid_voorop_opkomen_voor_elkaar.png")
    },
    {
        label: "Wij helpen Nederland vooruit!", 
        image: require("@/assets/images/posters/vrijheid-voorop/poster_vrijheid_voorop_wij_helpen_nederland_vooruit.png")
    },
    {
        label: "Naastenliefde en gerechtigheid", 
        image: require("@/assets/images/posters/vrijheid-voorop/poster_vrijheid_voorop_naastenliefde_en_gerechtigheid.png")
    }]
},
{
    image: require("@/assets/images/parties/logo_3.png"),
    slogans: [{
        label: "Vrijheid voor allen", 
        image: require("@/assets/images/posters/burger-belangen/poster_burger_belangen_vrijheid_voor_allen.png")
    },
    {
        label: "De jeugd heeft de toekomst", 
        image: require("@/assets/images/posters/burger-belangen/poster_burger_belangen_de_jeugd_heeft_de_toekomst.png")
    },
    {
        label: "Meer inspraak voor iedereen", 
        image: require("@/assets/images/posters/burger-belangen/poster_burger_belangen_meer_inspraak_voor_iedereen.png")
    },
    {
        label: "Samen staan we sterk", 
        image: require("@/assets/images/posters/burger-belangen/poster_burger_belangen_samen_staan_we_sterk.png")
    },
    {
        label: "Dat kan groener", 
        image: require("@/assets/images/posters/burger-belangen/poster_burger_belangen_dat_kan_groener.png")
    },
    {
        label: "Maakt Nederland beter", 
        image: require("@/assets/images/posters/burger-belangen/poster_burger_belangen_maakt_nederland_beter.png")
    },
    {
        label: "Opkomen voor elkaar", 
        image: require("@/assets/images/posters/burger-belangen/poster_burger_belangen_opkomen_voor_elkaar.png")
    },
    {
        label: "Wij helpen Nederland vooruit!", 
        image: require("@/assets/images/posters/burger-belangen/poster_burger_belangen_wij_helpen_nederland_vooruit.png")
    },
    {
        label: "Naastenliefde en gerechtigheid", 
        image: require("@/assets/images/posters/burger-belangen/poster_burger_belangen_naastenliefde_en_gerechtigheid.png")
    }]
},
{
    image: require("@/assets/images/parties/logo_4.png"),
    slogans: [{
        label: "Vrijheid voor allen", 
        image: require("@/assets/images/posters/modern-progressief/poster_modern_progressief_vrijheid_voor_allen.png")
    },
    {
        label: "De jeugd heeft de toekomst", 
        image: require("@/assets/images/posters/modern-progressief/poster_modern_progressief_de_jeugd_heeft_de_toekomst.png")
    },
    {
        label: "Meer inspraak voor iedereen", 
        image: require("@/assets/images/posters/modern-progressief/poster_modern_progressief_meer_inspraak_voor_iedereen.png")
    },
    {
        label: "Samen staan we sterk", 
        image: require("@/assets/images/posters/modern-progressief/poster_modern_progressief_samen_staan_we_sterk.png")
    },
    {
        label: "Dat kan groener", 
        image: require("@/assets/images/posters/modern-progressief/poster_modern_progressief_dat_kan_groener.png")
    },
    {
        label: "Maakt Nederland beter", 
        image: require("@/assets/images/posters/modern-progressief/poster_modern_progressief_maakt_nederland_beter.png")
    },
    {
        label: "Opkomen voor elkaar", 
        image: require("@/assets/images/posters/modern-progressief/poster_modern_progressief_opkomen_voor_elkaar.png")
    },
    {
        label: "Wij helpen Nederland vooruit!", 
        image: require("@/assets/images/posters/modern-progressief/poster_modern_progressief_wij_helpen_nederland_vooruit.png")
    },
    {
        label: "Naastenliefde en gerechtigheid", 
        image: require("@/assets/images/posters/modern-progressief/poster_modern_progressief_naastenliefde_en_gerechtigheid.png")
    }]
},
{
    image: require("@/assets/images/parties/logo_5.png"),
    slogans: [{
        label: "Vrijheid voor allen", 
        image: require("@/assets/images/posters/christen-combinatie/poster_christen_combinatie_vrijheid_voor_allen.png")
    },
    {
        label: "De jeugd heeft de toekomst", 
        image: require("@/assets/images/posters/christen-combinatie/poster_christen_combinatie_de_jeugd_heeft_de_toekomst.png")
    },
    {
        label: "Meer inspraak voor iedereen", 
        image: require("@/assets/images/posters/christen-combinatie/poster_christen_combinatie_meer_inspraak_voor_iedereen.png")
    },
    {
        label: "Samen staan we sterk", 
        image: require("@/assets/images/posters/christen-combinatie/poster_christen_combinatie_samen_staan_we_sterk.png")
    },
    {
        label: "Dat kan groener", 
        image: require("@/assets/images/posters/christen-combinatie/poster_christen_combinatie_dat_kan_groener.png")
    },
    {
        label: "Maakt Nederland beter", 
        image: require("@/assets/images/posters/christen-combinatie/poster_christen_combinatie_maakt_nederland_beter.png")
    },
    {
        label: "Opkomen voor elkaar", 
        image: require("@/assets/images/posters/christen-combinatie/poster_christen_combinatie_opkomen_voor_elkaar.png")
    },
    {
        label: "Wij helpen Nederland vooruit!", 
        image: require("@/assets/images/posters/christen-combinatie/poster_christen_combinatie_wij_helpen_nederland_vooruit.png")
    },
    {
        label: "Naastenliefde en gerechtigheid", 
        image: require("@/assets/images/posters/christen-combinatie/poster_christen_combinatie_naastenliefde_en_gerechtigheid.png")
    }]
},
{
    image: require("@/assets/images/parties/logo_6.png"),
    slogans: [{
        label: "Vrijheid voor allen", 
        image: require("@/assets/images/posters/partij-van-morgen/poster_partij_van_morgen_vrijheid_voor_allen.png")
    },
    {
        label: "De jeugd heeft de toekomst", 
        image: require("@/assets/images/posters/partij-van-morgen/poster_partij_van_morgen_de_jeugd_heeft_de_toekomst.png")
    },
    {
        label: "Meer inspraak voor iedereen", 
        image: require("@/assets/images/posters/partij-van-morgen/poster_partij_van_morgen_meer_inspraak_voor_iedereen.png")
    },
    {
        label: "Samen staan we sterk", 
        image: require("@/assets/images/posters/partij-van-morgen/poster_partij_van_morgen_samen_staan_we_sterk.png")
    },
    {
        label: "Dat kan groener", 
        image: require("@/assets/images/posters/partij-van-morgen/poster_partij_van_morgen_dat_kan_groener.png")
    },
    {
        label: "Maakt Nederland beter", 
        image: require("@/assets/images/posters/partij-van-morgen/poster_partij_van_morgen_maakt_nederland_beter.png")
    },
    {
        label: "Opkomen voor elkaar", 
        image: require("@/assets/images/posters/partij-van-morgen/poster_partij_van_morgen_opkomen_voor_elkaar.png")
    },
    {
        label: "Wij helpen Nederland vooruit!", 
        image: require("@/assets/images/posters/partij-van-morgen/poster_partij_van_morgen_wij_helpen_nederland_vooruit.png")
    },
    {
        label: "Naastenliefde en gerechtigheid", 
        image: require("@/assets/images/posters/partij-van-morgen/poster_partij_van_morgen_naastenliefde_en_gerechtigheid.png")
    }]
},
{
    image: require("@/assets/images/parties/logo_7.png"),
    slogans: [{
        label: "Vrijheid voor allen", 
        image: require("@/assets/images/posters/jongeren-partij/poster_jongeren_partij_vrijheid_voor_allen.png")
    },
    {
        label: "De jeugd heeft de toekomst", 
        image: require("@/assets/images/posters/jongeren-partij/poster_jongeren_partij_de_jeugd_heeft_de_toekomst.png")
    },
    {
        label: "Meer inspraak voor iedereen", 
        image: require("@/assets/images/posters/jongeren-partij/poster_jongeren_partij_meer_inspraak_voor_iedereen.png")
    },
    {
        label: "Samen staan we sterk", 
        image: require("@/assets/images/posters/jongeren-partij/poster_jongeren_partij_samen_staan_we_sterk.png")
    },
    {
        label: "Dat kan groener", 
        image: require("@/assets/images/posters/jongeren-partij/poster_jongeren_partij_dat_kan_groener.png")
    },
    {
        label: "Maakt Nederland beter", 
        image: require("@/assets/images/posters/jongeren-partij/poster_jongeren_partij_maakt_nederland_beter.png")
    },
    {
        label: "Opkomen voor elkaar", 
        image: require("@/assets/images/posters/jongeren-partij/poster_jongeren_partij_opkomen_voor_elkaar.png")
    },
    {
        label: "Wij helpen Nederland vooruit!", 
        image: require("@/assets/images/posters/jongeren-partij/poster_jongeren_partij_wij_helpen_nederland_vooruit.png")
    },
    {
        label: "Naastenliefde en gerechtigheid", 
        image: require("@/assets/images/posters/jongeren-partij/poster_jongeren_partij_naastenliefde_en_gerechtigheid.png")
    }]
},
{
    image: require("@/assets/images/parties/logo_8.png"),
    slogans: [{
        label: "Vrijheid voor allen", 
        image: require("@/assets/images/posters/nederland-gezond/poster_nederland_gezond_vrijheid_voor_allen.png")
    },
    {
        label: "De jeugd heeft de toekomst", 
        image: require("@/assets/images/posters/nederland-gezond/poster_nederland_gezond_de_jeugd_heeft_de_toekomst.png")
    },
    {
        label: "Meer inspraak voor iedereen", 
        image: require("@/assets/images/posters/nederland-gezond/poster_nederland_gezond_meer_inspraak_voor_iedereen.png")
    },
    {
        label: "Samen staan we sterk", 
        image: require("@/assets/images/posters/nederland-gezond/poster_nederland_gezond_samen_staan_we_sterk.png")
    },
    {
        label: "Dat kan groener", 
        image: require("@/assets/images/posters/nederland-gezond/poster_nederland_gezond_dat_kan_groener.png")
    },
    {
        label: "Maakt Nederland beter", 
        image: require("@/assets/images/posters/nederland-gezond/poster_nederland_gezond_maakt_nederland_beter.png")
    },
    {
        label: "Opkomen voor elkaar", 
        image: require("@/assets/images/posters/nederland-gezond/poster_nederland_gezond_opkomen_voor_elkaar.png")
    },
    {
        label: "Wij helpen Nederland vooruit!", 
        image: require("@/assets/images/posters/nederland-gezond/poster_nederland_gezond_wij_helpen_nederland_vooruit.png")
    },
    {
        label: "Naastenliefde en gerechtigheid", 
        image: require("@/assets/images/posters/nederland-gezond/poster_nederland_gezond_naastenliefde_en_gerechtigheid.png")
    }]
}];