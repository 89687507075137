import {createRouter, createWebHistory, RouteRecordRaw} from "vue-router";

// Pages
import HomePage from "@/pages/HomePage.vue";
import Photopage from "@/pages/Photopage.vue";
import Emailpage from "@/pages/Emailpage.vue";
import ChooseParty from "@/pages/ChooseParty.vue";
import ChooseSlogan from "@/pages/ChooseSlogan.vue";
import UploadPosterPage from "@/pages/UploadPosterPage.vue";
import PosterPreviewPage from "@/pages/PosterPreviewPage.vue";
import MonochromePosterPage from "@/pages/MonochromePosterPage.vue";

const routes: Array<RouteRecordRaw> = [{
    path: "/",
    name: "home",
    component: HomePage
},
{
    path: "/foto-maken",
    name: "photo",
    component: Photopage
},
{
    path: "/partij-kiezen",
    name: "choose-party",
    component: ChooseParty
},
{
    path: "/slogan-kiezen",
    name: "choose-slogan",
    component: ChooseSlogan
},
{
    path: "/poster-bekijken",
    name: "preview-poster",
    component: PosterPreviewPage
},
{
    path: "/email-invullen",
    name: "email-input",
    component: Emailpage
},
{
    path: "/poster-versturen",
    name: "upload-poster",
    component: UploadPosterPage
},
{
    path: "/monochrome-posters",
    name: "monochrome-posters",
    component: MonochromePosterPage
}];

const router = createRouter({
    routes,
    history: createWebHistory(process.env.BASE_URL),
});

export default router;